import { FC, useEffect, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { useApproveTaskMutation, useDeleteFileMutation, useLazyDownloadFileQuery } from '../../../api/tenders';
import { downloadFileFunc } from '../../../api/axiosCruds';
import { UploadFileModal } from '../../Modals/UploadFileModal';
import { Loader } from '../../Loader/Loader';

import TenderMandat from '../../../assets/TenderMandat.svg';
import edit from '../../../assets/editAdminIcon.svg';
import deleteIcon from '../../../assets/DeleteIcon.svg';
import plus from '../../../assets/Plus, Add.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import done from '../../../assets/Done, Check.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import uploadIconGrey from '../../../assets/uploadBtn-grey.svg';
import i from '../../../assets/i.svg';
import file from '../../../assets/Documents, File, Upload.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';

import moment from 'moment';

interface DefineMeetingTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTaskId: React.Dispatch<React.SetStateAction<string | number | null>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setDeadlineModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsCreateTenderMandatModal: React.Dispatch<React.SetStateAction<boolean>>;
    taskId: string | number | null;
    messageApi: any;
}

export const DefineMandatTasks: FC<DefineMeetingTaskProps> = ({
    data,
    role,
    setDeadlineModal,
    setTaskId,
    setIsCreateTenderMandatModal,
    taskId,
    messageApi,
    setTrigger
}) => {
    const [isCreateMandate, setIsCreateMandate] = useState<any>(false);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [isUploadModal, setIsUploadModal] = useState<boolean>(false);

    const [deleteFile, { isLoading: isLoadingDeleteFile }] = useDeleteFileMutation();

    const [approveTask] = useApproveTaskMutation();

    const approveTaskFunc = async (taskId: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const deleteFileFunc = async () => {
        if (role === 'school_user') {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file: data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.school_user[0].uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        } else {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file:
                        data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.tender_representative[0]
                            .uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        }
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];

                downloadPDF(response, name);
            })
            .catch((err) => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });
        if (filename.includes('pdf')) {
            let headerLine = response.headers['content-disposition'];

            if (headerLine) {
                let startFileNameIndex = headerLine.indexOf('"') + 1;
                let endFileNameIndex = headerLine.lastIndexOf('"');
                filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            }
        }
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'define_tender_mandate') {
                        if (role !== 'school_user') {
                            if (
                                elem.status === 'pending_tr' &&
                                !elem.files.tender_representative.length &&
                                !elem?.approved
                            ) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task task `}>
                                            <div className="task__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            {role === 'tender_representative' && (
                                                <img
                                                    src={plus}
                                                    className="task__plus"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsCreateTenderMandatModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </>
                                );
                            }
                            if (
                                elem.status === 'pending_tr' &&
                                elem.files.tender_representative.length &&
                                !elem?.approved
                            ) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.tender_representative[0]?.name}
                                                        </div>
                                                    </div>
                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__name">My Comment</div>
                                                            </div>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>

                                                {role !== 'super_admin' && (
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeleteFileModal(true);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (
                                (elem.status === 'pending_su' && !elem?.approved) ||
                                elem.status === 'need_resend_su' ||
                                elem.status === 'resend_su'
                            ) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>

                                                <div className="task-complete__title-row">
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.tender_representative[0]?.name}
                                                        </div>
                                                    </div>
                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__name">My Comment</div>
                                                            </div>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'need_approve_tr' && !elem?.approved) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>
                                                {role !== 'super_admin' && (
                                                    <div className="task-complete__title-row">
                                                        <div
                                                            className={`task-complete__decline ${
                                                                elem.file_downloaded ? '' : 'disable'
                                                            }`}
                                                            onClick={() => {
                                                                if (elem.file_downloaded) {
                                                                    setTaskId(elem.id);
                                                                    setIsUploadModal(true);
                                                                }
                                                            }}
                                                        >
                                                            Send Back
                                                        </div>

                                                        <div
                                                            className={`task-complete__approved ${
                                                                !elem.file_downloaded ? 'disabled-blue-button' : ''
                                                            }`}
                                                            onClick={() => {
                                                                if (elem.file_downloaded) {
                                                                    approveTaskFunc(elem.id);
                                                                }
                                                            }}
                                                        >
                                                            Approve
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.school_user[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'need_approve_tr' && elem?.approved) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {role !== 'super_admin' && (
                                                        <div
                                                            className="task-complete__decline"
                                                            onClick={() => {
                                                                if (elem.file_downloaded) {
                                                                    setTaskId(elem.id);
                                                                    setIsUploadModal(true);
                                                                }
                                                            }}
                                                        >
                                                            Send Back
                                                        </div>
                                                    )}
                                                    <div className="task-complete__approved-active">
                                                        <img src={done} />
                                                        Approved
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />

                                                        {elem.files.school_user[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                );
                            }
                            if (
                                elem.status === 'resend_tr' &&
                                !elem?.approved &&
                                elem.files.tender_representative[0]?.uuid
                            ) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.school_user[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <img src={ReceivedFile} className="upload-download-icon" />{' '}
                                                            {elem.files.tender_representative[0]?.name}
                                                        </div>
                                                    </div>
                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__name">My Comment</div>
                                                            </div>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>

                                                {role !== 'super_admin' && (
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeleteFileModal(true);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'completed') {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {role !== 'super_admin' && (
                                                        <div
                                                            className={`task-complete__decline ${
                                                                elem.file_downloaded ? '' : 'disable'
                                                            }`}
                                                            onClick={() => {
                                                                if (elem.file_downloaded) {
                                                                    setTaskId(elem.id);
                                                                    setIsUploadModal(true);
                                                                }
                                                            }}
                                                        >
                                                            Send Back
                                                        </div>
                                                    )}
                                                    <div className="task-complete__approved-active">
                                                        <img src={done} />
                                                        Approved
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">Received File</div>
                                                    <div className="task-complete__value file">
                                                        <img src={ReceivedFile} className="upload-download-icon" />
                                                        {elem.files.school_user[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div
                                                        className={`task-complete__download-btn `}
                                                        onClick={() => {
                                                            downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                        }}
                                                    >
                                                        <img src={downloadIcon} className="upload-download-icon" />
                                                        Download
                                                    </div>
                                                </div>
                                            </div>
                                            {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (
                                (elem.status === 'pending_su' && !elem?.approved && !elem.files.school_user[0]?.uuid) ||
                                elem.status === 'need_resend_su'
                            ) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                This document is shared with suppliers and confirms that
                                                                you have appointed us to assist you with your tender. It
                                                                outlines our responsibilities as well as any fees that
                                                                your appointed supplier will need to pay in the event of
                                                                their appointment.
                                                                <br />
                                                                <b>Instruction:</b> Please return a signed copy of the
                                                                Tender Mandate on your school’s letterhead.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="i" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <Tooltip
                                                        title="To upload file, you need to download Completed Tender Mandate first"
                                                        overlayClassName={
                                                            elem.file_downloaded
                                                                ? 'custom-tooltip'
                                                                : 'custom-tooltip-disabled'
                                                        }
                                                    >
                                                        <div
                                                            className={`task-complete__download-btn ${
                                                                elem.file_downloaded ? '' : 'disable'
                                                            }`}
                                                            onClick={() => {
                                                                if (elem.file_downloaded) {
                                                                    setTaskId(elem.id);
                                                                    setIsCreateMandate(true);
                                                                }
                                                            }}
                                                        >
                                                            <img
                                                                src={elem.file_downloaded ? file : uploadIconGrey}
                                                                className="upload-download-icon"
                                                            />
                                                            Upload File
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Received File</div>
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files.tender_representative[0]?.uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                    <div className="task-complete__info-wrap">
                                                        <div className="task-complete__info">
                                                            <div className="task-complete__wrap column">
                                                                <div className="task-complete__name">Uploaded File</div>
                                                                <div className="task-complete__value file">
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {elem.files.school_user[0]?.name}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <>
                                                            <div className="task-complete__wrap column">
                                                                <div className="task-complete__name">My Comment</div>
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.school_user[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        <img
                                                            src={deleteIcon}
                                                            className="task-complete__edit"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setIsDeleteFileModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (
                                elem.status === 'resend_tr' &&
                                !elem?.approved &&
                                !elem.declined &&
                                elem.files.school_user[0]?.uuid
                            ) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                This document is shared with suppliers and confirms that
                                                                you have appointed us to assist you with your tender. It
                                                                outlines our responsibilities as well as any fees that
                                                                your appointed supplier will need to pay in the event of
                                                                their appointment.
                                                                <br />
                                                                <b>Instruction:</b> Please return a signed copy of the
                                                                Tender Mandate on your school’s letterhead.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="i" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <>
                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                {elem.files.school_user[0]?.name && (
                                                    <>
                                                        <div className="task-complete__info">
                                                            <div className="task-complete__wrap column">
                                                                <div className="task-complete__name">Uploaded File</div>
                                                                <div className="task-complete__value file">
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {elem.files.school_user[0]?.name}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                            <>
                                                                <div className="task-complete__wrap column">
                                                                    <div className="task-complete__name">
                                                                        My Comment
                                                                    </div>
                                                                    <div className="task-complete__value">
                                                                        {
                                                                            elem.comments.files[
                                                                                elem.files.school_user[0]?.uuid
                                                                            ]
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'pending_tr' && !elem?.approved) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                This document is shared with suppliers and confirms that
                                                                you have appointed us to assist you with your tender. It
                                                                outlines our responsibilities as well as any fees that
                                                                your appointed supplier will need to pay in the event of
                                                                their appointment.
                                                                <br />
                                                                <b>Instruction:</b> Please return a signed copy of the
                                                                Tender Mandate on your school’s letterhead.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="i" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Received File</div>
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files.tender_representative[0]?.uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                <div className="task-complete__info-wrap">
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Uploaded File</div>
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.school_user[0]?.name}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap column">
                                                                <div className="task-complete__name">My Comment</div>
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.school_user[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeleteFileModal(true);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (
                                (elem.status === 'pending_su' && !elem?.approved && elem.files.school_user[0]?.uuid) ||
                                elem.status === 'resend_su'
                            ) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                This document is shared with suppliers and confirms that
                                                                you have appointed us to assist you with your tender. It
                                                                outlines our responsibilities as well as any fees that
                                                                your appointed supplier will need to pay in the event of
                                                                their appointment.
                                                                <br />
                                                                <b>Instruction:</b> Please return a signed copy of the
                                                                Tender Mandate on your school’s letterhead.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="i" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Received File</div>
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files.tender_representative[0]?.uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                {elem.files.school_user[0]?.name && (
                                                    <div className="task-complete__info-wrap">
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Uploaded File</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.school_user[0]?.name}
                                                            </div>
                                                        </div>

                                                        <>
                                                            {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                                <>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            My Comment
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {
                                                                                elem.comments.files[
                                                                                    elem.files.school_user[0]?.uuid
                                                                                ]
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                        <img
                                                            src={deleteIcon}
                                                            className="task-complete__edit"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setIsDeleteFileModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'need_approve_tr' && elem.files.school_user[0]?.uuid) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                This document is shared with suppliers and confirms that
                                                                you have appointed us to assist you with your tender. It
                                                                outlines our responsibilities as well as any fees that
                                                                your appointed supplier will need to pay in the event of
                                                                their appointment.
                                                                <br />
                                                                <b>Instruction:</b> Please return a signed copy of the
                                                                Tender Mandate on your school’s letterhead.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="i" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {' '}
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap column">
                                                            <div className="task-complete__name">Received File</div>
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.tender_representative[0]?.name}
                                                            </div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files.tender_representative[0]?.uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                {elem.files.school_user[0]?.name && (
                                                    <div className="task-complete__info-wrap">
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Uploaded File</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.school_user[0]?.name}
                                                            </div>
                                                        </div>

                                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                            <>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">
                                                                        My Comment
                                                                    </div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {
                                                                            elem.comments.files[
                                                                                elem.files.school_user[0]?.uuid
                                                                            ]
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'completed' && elem?.approved) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                This document is shared with suppliers and confirms that
                                                                you have appointed us to assist you with your tender. It
                                                                outlines our responsibilities as well as any fees that
                                                                your appointed supplier will need to pay in the event of
                                                                their appointment.
                                                                <br />
                                                                <b>Instruction:</b> Please return a signed copy of the
                                                                Tender Mandate on your school’s letterhead.
                                                            </div>
                                                        }
                                                        overlayClassName={'custom-tooltip-disabled'}
                                                    >
                                                        <img src={i} className="i" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {' '}
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <>
                                                    <div className="task-complete__info">
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Received File</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.tender_representative[0]?.name}
                                                            </div>
                                                        </div>

                                                        <div className="task-complete__wrap">
                                                            <div
                                                                className={`task-complete__download-btn `}
                                                                onClick={() => {
                                                                    downloadFunc(
                                                                        elem.files.tender_representative[0]?.uuid,
                                                                        elem.id
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={downloadIcon}
                                                                    className="upload-download-icon"
                                                                />
                                                                Download
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {elem.comments.files[elem.files.tender_representative[0]?.uuid] && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.tender_representative[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                    <div className="task-complete__info-wrap">
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Uploaded File</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value file">
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {elem.files.school_user[0]?.name}
                                                            </div>
                                                        </div>

                                                        <>
                                                            <div className="task-complete__wrap column">
                                                                <div className="task-complete__name">My Comment</div>
                                                                <div className="task-complete__value">
                                                                    {
                                                                        elem.comments.files[
                                                                            elem.files.school_user[0]?.uuid
                                                                        ]
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        }
                    }
                })}
            {isCreateMandate && (
                <UploadFileModal
                    openModal={isCreateMandate}
                    setOpenModal={setIsCreateMandate}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    title="Tender Mandate"
                    subtitle="Upload a file to import tender mandate template"
                />
            )}
            {isUploadModal && (
                <UploadFileModal
                    openModal={isUploadModal}
                    setOpenModal={setIsUploadModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    title="Tender Mandate Template"
                    subtitle="Upload a file to import tender mandate template"
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFileFunc}>
                                {isLoadingDeleteFile ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
